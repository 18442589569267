import {
  Chain,
  ChainID,
  ChainName,
  Ticket,
  TicketStatus,
  TicketStatusResult,
  TicketAction,
  Token,
} from "../types";
import EvmRouteService from "./EvmRouteService";
import BitcoinCustomsService from "./BitcoinCustomsService";
import BitcoinBrc20CustomsService from "./BitcoinBrc20CustomsService";
import ICPCustomService from "./ICPCustomsService";
import ICPRouteService from "./ICPRouteService";
import { isEvmChain } from "@utils/chains";
import SolanaRouteService from "./SolanaRouteService";
import BitcoinService from "./BitcoinService";
import OsmosisRouteService from "./OsmosisRouteService";
import BitcoinCkBTCService from "./BitcoinckBTCService";
import TonRouteService from "./TonRouteService";
import DogeCustomsService from "./DogeCustomsService";
import SuiRouteService from "./SuiRouteService";

export default class ServiceFactory {
  static createService(chain: Chain) {
    if (isEvmChain(chain.chain_id)) {
      return new EvmRouteService(chain);
    }
    switch (chain.chain_id) {
      case ChainID.eICP:
        return new ICPRouteService(chain);
      case ChainID.sICP:
        return new ICPCustomService(chain);
      case ChainID.Bitcoin:
        return new BitcoinCustomsService(chain);
      case ChainID.Solana:
        return new SolanaRouteService(chain);
      case ChainID.BitcoinBrc20:
        return new BitcoinBrc20CustomsService(chain);
      case ChainID.Osmosis:
        return new OsmosisRouteService(chain);
      case ChainID.BitcoinckBTC:
        return new BitcoinCkBTCService(chain);
      case ChainID.Ton:
        return new TonRouteService(chain);
      case ChainID.Doge:
        return new DogeCustomsService(chain);
      case ChainID.Sui:
        return new SuiRouteService(chain);
      default:
        throw new Error("Service not found");
    }
  }

  static async getTokenList(chain: Chain) {
    return await ServiceFactory.createService(chain)?.getTokenList();
  }

  static validateAddress(chainName: ChainName, address: string): boolean {
    if (isEvmChain(chainName)) {
      return EvmRouteService.validateAddress(address);
    }
    switch (chainName) {
      case ChainName.ICP:
        return ICPCustomService.validateAddress(address);
      case ChainName.Bitcoin:
        return BitcoinService.validateAddress(address);
      case ChainName.Solana:
        return SolanaRouteService.validateAddress(address);
      case ChainName.Osmosis:
        return OsmosisRouteService.validateAddress(address);
      case ChainName.Ton:
        return TonRouteService.validateAddress(address);
      case ChainName.Doge:
        return DogeCustomsService.validateAddress(address);
      default:
        return true;
    }
  }

  static async processMintTicket(
    ticket: Ticket,
    chains: Chain[],
  ): Promise<(TicketStatusResult & { finalized: boolean }) | undefined> {
    // Only process mint tickets, and only if the ticket has a ticket_id and no mint_tx_hash
    if (
      ticket.type !== TicketAction.Mint ||
      !ticket.ticket_id ||
      ticket.mint_tx_hash
    ) {
      return;
    }
    const btcChain = chains.find((c) => c.chain_id === ChainID.Bitcoin);
    if (!btcChain) {
      return;
    }
    const service = ServiceFactory.createService(
      btcChain,
    ) as BitcoinCustomsService;
    const mintResult = await service.getTicketStatus(ticket.ticket_id);
    let finalized = false;

    if (mintResult.status === TicketStatus.Finalized && mintResult.tx_hash) {
      const result = await service.generateTicket({
        ...ticket,
        mint_tx_hash: mintResult.tx_hash,
      });
      finalized = !!result.ticket.finalized;
    }
    return { ...mintResult, finalized };
  }

  static async getTicketStatus(
    ticket: Ticket,
    chains: Chain[],
  ): Promise<TicketStatus> {
    if (!ticket.ticket_id) {
      return TicketStatus.Unknown;
    }
    const btcChain = chains.find((c) => c.chain_id === ChainID.Bitcoin);

    if (ticket.src_chain === ChainID.Bitcoin) {
      if (btcChain) {
        const service = ServiceFactory.createService(
          btcChain,
        ) as BitcoinCustomsService;
        const status = await service.getOutputTicketStatus(ticket.ticket_id);
        if (status !== TicketStatus.Finalized) {
          return status;
        }
      }
    }
    if (btcChain && ticket.type === TicketAction.Mint) {
      if (ticket.mint_tx_hash) {
        const service = ServiceFactory.createService(
          btcChain,
        ) as BitcoinCustomsService;
        const status = await service.getOutputTicketStatus(ticket.mint_tx_hash);
        return status;
      } else {
        return TicketStatus.Pending;
      }
    } else {
      const chain = chains.find((c) => c.chain_id === ticket.dst_chain);

      if (chain) {
        const service = ServiceFactory.createService(chain);
        if (!service) {
          throw new Error("Service not found");
        }
        const result = await service.getTicketStatus(ticket.ticket_id);
        return result?.status;
      }
    }

    return TicketStatus.Unknown;
  }

  static getTicketLink(ticketId?: string) {
    if (!ticketId) {
      return "#";
    }
    return `https://explorer.omnity.network/ticket/${ticketId}`;
  }

  static getTicketIdLink(ticket: Pick<Ticket, "src_chain" | "ticket_id">) {
    if (!ticket.ticket_id?.trim()) {
      return "#";
    }
    if (isEvmChain(ticket.src_chain)) {
      return EvmRouteService.getTxHashLink(ticket.src_chain, ticket.ticket_id);
    }
    switch (ticket.src_chain) {
      case ChainID.eICP:
      case ChainID.sICP:
        return "#";
      case ChainID.Bitcoin:
      case ChainID.BitcoinBrc20:
        return `https://mempool.space/tx/${ticket.ticket_id}`;
      case ChainID.Solana:
        return `https://solscan.io/tx/${ticket.ticket_id}`;
      case ChainID.Ton:
        return `https://tonviewer.com/transaction/${encodeURIComponent(ticket.ticket_id)}`;
      case ChainID.Osmosis:
        return `https://www.mintscan.io/osmosis/tx/${ticket.ticket_id}`;
      case ChainID.Doge:
        return `https://dogechain.info/tx/${ticket.ticket_id}`;
      case ChainID.Sui:
        return `https://suivision.xyz/txblock/${ticket.ticket_id}`;
      default:
        break;
    }
    return "#";
  }

  static getTokenLink(token: Pick<Token, "chain_id" | "id" | "symbol">) {
    if (isEvmChain(token.chain_id)) {
      return EvmRouteService.getTokenLink(token.chain_id, token.id);
    }
    switch (token.chain_id) {
      case ChainID.eICP:
      case ChainID.sICP:
        return `https://ic.house/token/${token.id}`;
      case ChainID.Bitcoin:
        return `https://www.runescan.net/runes/${token.id}`;
      case ChainID.BitcoinBrc20:
        return `https://unisat.io/brc20/${token.id}`;
      case ChainID.Solana:
        return `https://tonviewer.com/${token.id}`;
      case ChainID.Osmosis:
        return `https://app.osmosis.zone/assets/${token.symbol}`;
      case ChainID.Doge:
        return "https://dogecoin.com";
      case ChainID.Sui:
        return `https://suivision.xyz/coin/${token.id}/txs`;
      default:
        break;
    }
    return "#";
  }

  static async getTxStatus(ticket: Ticket) {
    if (ticket.ticket_id) {
      if (isEvmChain(ticket.src_chain)) {
        return await EvmRouteService.getTxStatus(ticket);
      }
      switch (ticket.src_chain) {
        case ChainID.Bitcoin:
        case ChainID.BitcoinBrc20:
          return await BitcoinService.getTxStatus(ticket);
        case ChainID.Solana:
          return await SolanaRouteService.getTxStatus(ticket);
        case ChainID.Ton:
          return await TonRouteService.getTxStatus(ticket);
        case ChainID.Osmosis:
          return await OsmosisRouteService.getTxStatus(ticket);
        case ChainID.Sui:
          return await SuiRouteService.getTxStatus(ticket);
        default:
          break;
      }
    }

    return "pending";
  }

  static waitTxStatusBeforeGenerateTicket(ticket: Pick<Ticket, "src_chain">) {
    return !(
      ticket.src_chain === ChainID.sICP || ticket.src_chain === ChainID.eICP
    );
  }
}
