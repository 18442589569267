import {
  BridgeStep,
  Chain,
  ChainID,
  OnBridgeParams,
  Ticket,
  TicketStatus,
  Token,
  OnBurnParams,
  TicketStatusResult,
  TicketAction,
  BridgeFee,
  GenerateTicketResult,
} from "../types";
import BaseService from "./BaseService";
import { ActorSubclass } from "@dfinity/agent";
import {
  idlFactory as BitcoinCustomInterfaceFactory,
  _SERVICE,
} from "./candids/BitcoinCustoms.did";
import { createActor } from "./candids/index";
import { formatGenerateTicketError } from "@utils/helper";
import { isEvmChain } from "@utils/chains";
import posthog from "posthog-js";

export const runesIndexerApi =
  "https://hasura-graphql-engine-219952077564.us-central1.run.app";

export default class BitcoinCustomsService extends BaseService {
  actor: ActorSubclass<_SERVICE>;
  static BTC_CONFIRMATIONS_LIMIT = 4;

  constructor(chain: Chain) {
    super(chain);
    this.actor = createActor<_SERVICE>(
      chain.canister_id,
      BitcoinCustomInterfaceFactory,
    );
  }

  async getTokenList(): Promise<Token[]> {
    const tokenList = await this.actor.get_token_list();
    return tokenList.map(transformToken);
  }

  async fetchTokens(token_ids?: string[], address?: string): Promise<Token[]> {
    let tokenList = this.chain.token_list || [];
    if (Array.isArray(token_ids) && token_ids.length > 0) {
      tokenList = token_ids
        .map((id) => tokenList.find((r) => r.token_id === id))
        .filter((t) => !!t) as any;
    }

    if (!address) {
      return tokenList;
    }
    try {
      const tokens = await Promise.all(
        tokenList.map(async (t) => {
          const { balance, composed_balance } = await getRuneTokenBalance(
            t.id,
            address,
          );
          return { ...t, balance, composed_balance };
        }),
      );
      return tokens;
    } catch (error) {
      return tokenList;
    }
  }

  getBridgeSteps(token?: Token): BridgeStep[] {
    return [
      {
        title: "Prepare",
        description: "Generate deposit address",
      },
      {
        title: "Transfer",
        description: "Send your assets to the deposit address",
      },
    ];
  }

  async onBridge(params: OnBridgeParams): Promise<string> {
    const {
      targetAddr,
      targetChainId,
      setStep,
      transfer,
      sourceAddr,
      token,
      feeRate,
      amount,
    } = params;
    const depositAddr = await this.actor.get_btc_address({
      target_chain_id: targetChainId,
      receiver: targetAddr,
    });
    setStep && setStep(1);

    if (!transfer) {
      throw new Error("Transfer function is required");
    }
    const [[platform_fee], [platform_fee_collector]] =
      await this.actor.get_platform_fee(targetChainId);

    const tx_hash = await transfer({
      wallet_address: sourceAddr,
      receiver_address: depositAddr,
      rune_name: token.name,
      fee_rate: feeRate,
      amount,
      platform_fee: platform_fee ? Number(platform_fee.toString()) : undefined,
      platform_fee_collector,
    });

    setStep && setStep(2);

    return tx_hash;
  }

  onBurn(params: OnBurnParams): Promise<string> {
    throw new Error("Method not implemented.");
  }

  async generateTicket(ticket: Ticket): Promise<GenerateTicketResult> {
    if (!ticket.ticket_id) {
      throw new Error("Ticket not found");
    }
    if (BigInt(ticket.amount) === 0n) {
      throw new Error(
        `Ticket finalizing error: Invalid amount ${ticket.amount}`,
      );
    }
    const tokens = await this.fetchTokens([ticket.token]);
    const token = tokens[0];
    if (!token) {
      throw new Error("Token not found");
    }
    let result;
    if (ticket.type === TicketAction.Mint) {
      result = await this.actor.generate_ticket({
        txid: ticket.mint_tx_hash!,
        target_chain_id: ticket.src_chain,
        amount: BigInt(ticket.amount),
        receiver: isEvmChain(ticket.src_chain)
          ? ticket.receiver.toLowerCase()
          : ticket.receiver,
        rune_id: token.id,
      });
    } else {
      result = await this.actor.generate_ticket({
        txid: ticket.ticket_id,
        target_chain_id: ticket.dst_chain,
        amount: BigInt(ticket.amount),
        receiver: ticket.receiver,
        rune_id: token.id,
      });
    }

    const finializedTicket = { ticket: { ...ticket, finalized: true } };
    if ("Ok" in result) {
      posthog.capture("ticket generate ok", {
        ...ticket,
        token_id: ticket.token,
      });
      return finializedTicket;
    }

    const error = formatGenerateTicketError(result.Err);
    if (error?.includes("already exists")) {
      return finializedTicket;
    }
    posthog.capture("ticket generate error", {
      ...ticket,
      token_id: ticket.token,
      error,
    });
    return { message: error, ticket: { ...ticket, finalized: false } };
  }

  onMint(params: OnBridgeParams): Promise<string> {
    throw new Error("Method not implemented.");
  }

  async getTicketStatus(ticket_id: string): Promise<TicketStatusResult> {
    const res = await this.actor.release_token_status(ticket_id);
    let status = Object.keys(res)[0] as TicketStatus;
    const statusValue = Object.values(res)[0];
    let tx_hash = "";
    if (
      [
        TicketStatus.Confirmed,
        TicketStatus.Submitted,
        TicketStatus.Sending,
      ].includes(status)
    ) {
      status =
        TicketStatus.Confirmed === status ? TicketStatus.Finalized : status;
      tx_hash = statusValue ?? "";
    }
    return {
      status,
      tx_hash,
    };
  }

  async getOutputTicketStatus(ticket_id: string): Promise<TicketStatus> {
    const res = await this.actor.generate_ticket_status(ticket_id);
    const status = Object.keys(res)[0];
    return status as TicketStatus;
  }

  async getBridgeFee(
    targetChainId: ChainID,
    token?: Token,
  ): Promise<BridgeFee> {
    const [[platform_fee], []] =
      await this.actor.get_platform_fee(targetChainId);

    return Promise.resolve({
      fee: platform_fee ?? BigInt(0),
      symbol: "BTC",
      decimals: 8,
    });
  }
}

export function transformToken(hubToken: any) {
  const { decimals, icon, symbol, token_id, rune_id } = hubToken;
  const name = token_id.split("-")[2];
  const token: Token = {
    id: rune_id,
    decimals,
    icon: icon[0],
    symbol,
    token_id,
    balance: 0n,
    name,
    fee: 0n,
    chain_id: ChainID.BitcoinBrc20,
  };
  return token;
}

export async function getRuneTokenBalance(runeId: string, address?: string) {
  let balance = 0n;
  let available = 0n;
  try {
    if (!runeId) {
      throw new Error("Rune id is required");
    }
    if (!address) {
      throw new Error("Address is required");
    }
    const balanceResult = await fetch(
      `${runesIndexerApi}/api/rest/rune_balance_and_latest_change?rune_id=${runeId}&address=${address}`,
    ).then((res) => res.json());

    if (
      Array.isArray(balanceResult.rs_rune_balances) &&
      balanceResult.rs_rune_balances.length
    ) {
      balance = BigInt(balanceResult.rs_rune_balances[0].amount);
      available = balance;
    }
    if (
      Array.isArray(balanceResult.latest_rune_balance_change) &&
      balanceResult.latest_rune_balance_change.length
    ) {
      balanceResult.latest_rune_balance_change.forEach(
        (item: { amount: number; block: number; is_increased: boolean }) => {
          if (item.is_increased) {
            available = balance - BigInt(item.amount);
          }
        },
      );
    }
  } catch (error) {}
  return {
    balance,
    composed_balance: {
      available,
    },
  };
}
