import RevertButton from "../components/common/RevertButton";
import CurrentWallet from "../components/common/CurrentWallet";
import TokenSelector from "../components/TokenSelector";
import {
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack,
  Switch,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { useTransferContext } from "@context/TransferContext";
import { useEffect, useState } from "react";
import { ChainName, Token } from "../types";
import ChainButton from "@components/common/ChainButton";
import TokenBalance from "@components/TokenBalance";
import { ChainSubmitButton } from "@components/SubmitButton/BaseSubmit";
import TokenOnTargetChain from "@components/TokenOnTargetChain";
import { SubmitTransfer } from "@components/SubmitButton/SubmitButton";
import useServices from "@hooks/useServices";

export default function OmnityTransfer({ width }: { width?: number }) {
  const {
    sourceChain,
    targetChain,
    targetAddr,
    useConnectWalletForReceiver,
    onToggleConnectWalletForReceiver,
    onTargetAddrChange,
    token,
    onTokenChange,
    amount,
    onAmountChange,
    reversible,
    onRevert,
  } = useTransferContext();

  const [targetToken, setTargetToken] = useState<Token>();
  const [loadingTargetToken, setLoadingTargetToken] = useState(false);
  const { targetService } = useServices();

  useEffect(() => {
    if (targetService && token) {
      setLoadingTargetToken(true);
      targetService.fetchTokens([token.token_id]).then((tokens) => {
        setLoadingTargetToken(false);
        setTargetToken(tokens[0]);
      });
    }
  }, [targetService?.chain.chain_id, token?.token_id, targetToken?.token_id]);

  const inputBoxWrapperBg = useColorModeValue("white", "gray.800");
  const inputTextColor = useColorModeValue("gray.800", "gray.200");
  const textColor = useColorModeValue("#333", "#eee");
  const boxWrapperBg = useColorModeValue("#eee", "gray.700");

  return (
    <VStack w={{ base: "100%", md: width }} gap={6}>
      <VStack
        width="99%"
        p={{ base: 4, md: 6 }}
        borderRadius={8}
        gap={4}
        bg={inputBoxWrapperBg}
        color={textColor}
      >
        <Stack
          flexDir={{ base: "column", md: "row" }}
          alignItems="center"
          gap={4}
          w="100%"
        >
          <VStack w={{ base: "100%" }} flex={1} alignItems="flex-start" gap={1}>
            <HStack w="100%" justifyContent="space-between">
              <Text fontWeight={600}>From</Text>
              <CurrentWallet chain={sourceChain} isSource />
            </HStack>
            <ChainButton chainName={sourceChain} isSource />
          </VStack>

          <RevertButton reversible={reversible} onRevert={onRevert} />

          <VStack w={{ base: "100%" }} flex={1} alignItems="flex-start" gap={1}>
            <HStack w="100%" justifyContent="space-between">
              <Text fontWeight={600}>To</Text>
              {useConnectWalletForReceiver && targetAddr && (
                <CurrentWallet chain={targetChain} isSource={false} />
              )}
            </HStack>
            <ChainButton chainName={targetChain} />
          </VStack>
        </Stack>

        <VStack w="100%" alignItems="flex-start" gap={1}>
          <Text fontWeight={600}>Amount</Text>
          <VStack w="100%" bg={boxWrapperBg} borderRadius={4} p={4}>
            <HStack w="100%" py={2}>
              <Input
                placeholder="0"
                color={inputTextColor}
                flex={1}
                value={amount}
                fontSize={{
                  base: 36 * (amount.length > 6 ? 0.8 : 1),
                  md: 60 * (amount.length > 6 ? 0.8 : 1),
                }}
                py={`${amount.length > 6 ? 6 : 0}px`}
                lineHeight={1}
                px={0}
                fontWeight={600}
                borderWidth={0}
                height="100%"
                outline="none"
                _focus={{
                  borderWidth: 0,
                  outline: "none",
                  boxShadow: "none",
                }}
                onChange={(e) => {
                  const newValue = e.target.value;
                  // fraction not longer than decimals
                  let [_, fraction] = newValue.split(".");
                  if (fraction && fraction.length > (token?.decimals ?? 0)) {
                    return;
                  }
                  if (/^\d+(\.)?(\d+)?$/.test(newValue) || newValue === "") {
                    onAmountChange(e.target.value);
                  }
                }}
              />

              <TokenSelector token={token} setToken={onTokenChange} />
            </HStack>
            <HStack
              w="100%"
              alignItems="center"
              justifyContent="flex-end"
              color="#999"
            >
              <HStack fontSize={{ base: 14, md: 16 }}>
                <Text>Balance:</Text>
                <TokenBalance />
              </HStack>
            </HStack>
          </VStack>
        </VStack>

        <VStack w="100%" alignItems="flex-start" gap={1}>
          <HStack w="100%" justifyContent="space-between">
            <Text fontWeight={600}>Receiver</Text>
            <HStack cursor="pointer">
              <FormControl display="flex" alignItems="center">
                <FormLabel
                  htmlFor="connected-target-addr"
                  mb="0"
                  cursor="pointer"
                >
                  use connected wallet
                </FormLabel>
                <Switch
                  id="connected-target-addr"
                  isChecked={useConnectWalletForReceiver}
                  onChange={onToggleConnectWalletForReceiver}
                />
              </FormControl>
            </HStack>
          </HStack>
          <HStack w="100%" borderRadius={4}>
            {((useConnectWalletForReceiver && targetAddr) ||
              !useConnectWalletForReceiver) && (
              <Input
                color={inputTextColor}
                bg={boxWrapperBg}
                py={4}
                px={2}
                lineHeight={1}
                borderWidth={0}
                fontFamily="monospace"
                height="100%"
                outline="none"
                placeholder={
                  targetChain === ChainName.ICP
                    ? "ICP Principal ID"
                    : `${targetChain} Address`
                }
                value={targetAddr}
                onChange={(e) =>
                  !useConnectWalletForReceiver &&
                  onTargetAddrChange(e.target.value)
                }
                _focus={{
                  borderWidth: 0,
                  outline: "none",
                  boxShadow: "none",
                }}
              />
            )}
            {useConnectWalletForReceiver && !targetAddr && targetChain && (
              <ChainSubmitButton chainName={targetChain} forSubmit={false} />
            )}
          </HStack>
        </VStack>

        {token && (
          <TokenOnTargetChain
            chainName={targetChain}
            token={targetToken}
            isLoading={loadingTargetToken}
            service={targetService}
          />
        )}
      </VStack>

      <SubmitTransfer />
    </VStack>
  );
}
