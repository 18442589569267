import {
  BridgeStep,
  Chain,
  ChainID,
  OnBridgeParams,
  Ticket,
  TicketStatus,
  Token,
  OnBurnParams,
  TicketStatusResult,
  BridgeFee,
  GenerateTicketResult,
} from "../types";
import BaseService from "./BaseService";
import { ActorSubclass } from "@dfinity/agent";
import {
  idlFactory as BitcoinCustomInterfaceFactory,
  TokenResp,
  _SERVICE,
} from "./candids/BitcoinBrc20Customs.did";
import { createActor } from "./candids/index";
import { validate, Network } from "bitcoin-address-validation";
import { formatGenerateTicketError } from "@utils/helper";
import { isEvmChain } from "@utils/chains";
import posthog from "posthog-js";
import { formatUnits, parseAmount } from "src/utils/format";

export const runesIndexerApi =
  "https://hasura-secondary-graphql-engine-2252klcbva-uc.a.run.app";

export default class BitcoinBrc20CustomsService extends BaseService {
  actor: ActorSubclass<_SERVICE>;
  static BTC_CONFIRMATIONS_LIMIT = 4;

  constructor(chain: Chain) {
    super(chain);
    this.actor = createActor<_SERVICE>(
      chain.canister_id,
      BitcoinCustomInterfaceFactory,
    );
  }

  async getTokenList(): Promise<Token[]> {
    const tokenList = await this.actor.get_token_list();
    return tokenList.map(transformToken);
  }

  async fetchTokens(token_ids?: string[], address?: string): Promise<Token[]> {
    let tokenList = this.chain.token_list || [];
    if (Array.isArray(token_ids) && token_ids.length > 0) {
      tokenList = token_ids
        .map((id) => tokenList.find((r) => r.token_id === id))
        .filter((t) => !!t) as any;
    }

    if (!address) {
      return tokenList;
    }
    try {
      const tokens = await Promise.all(
        tokenList.map(async (t) => {
          const { balance, composed_balance } = await getBrc20TokenBalance(
            t.id,
            t.decimals,
            address,
          );
          return { ...t, balance, composed_balance };
        }),
      );
      return tokens;
    } catch (error) {
      return tokenList;
    }
  }

  getBridgeSteps(token?: Token): BridgeStep[] {
    return [
      {
        title: "Inscribe",
        description: "Generate a inscription",
      },
      {
        title: "Transfer",
        description: "Send inscription",
      },
    ];
  }

  async onBridge(params: OnBridgeParams): Promise<string> {
    const {
      targetAddr,
      targetChainId,
      setStep,
      transfer,
      sourceAddr,
      token,
      feeRate,
      amount,
    } = params;
    const depositAddr = await this.actor.get_deposit_addr();

    if (!setStep) {
      throw new Error("Inscribe function is required");
    }
    if (!transfer) {
      throw new Error("Transfer function is required");
    }
    const [[platform_fee], [platform_fee_collector]] =
      await this.actor.get_platform_fee(targetChainId);
    const tx_hash = await transfer(
      {
        sender: sourceAddr,
        receiver: isEvmChain(targetChainId)
          ? targetAddr.toLowerCase()
          : targetAddr,
        tick: token.symbol.toUpperCase(),
        fee_rate: feeRate,
        amount: formatUnits(amount, token.decimals),
        deposit_addr: depositAddr[0],
        deposit_public: depositAddr[1],
        target_chain: targetChainId,
        platform_fee: platform_fee
          ? Number(platform_fee.toString())
          : undefined,
        platform_fee_collector,
      },
      setStep,
    );

    return tx_hash;
  }

  onBurn(params: OnBurnParams): Promise<string> {
    throw new Error("Method not implemented.");
  }

  async generateTicket(ticket: Ticket): Promise<GenerateTicketResult> {
    if (!ticket.ticket_id) {
      throw new Error("Ticket not found");
    }
    if (BigInt(ticket.amount) === 0n) {
      throw new Error(
        `Ticket finalizing error: Invalid amount ${ticket.amount}`,
      );
    }
    const tokens = await this.fetchTokens([ticket.token]);
    const token = tokens[0];
    if (!token) {
      throw new Error("Token not found");
    }
    const result = await this.actor.generate_ticket({
      txid: ticket.ticket_id,
      token_id: ticket.token,
      target_chain_id: ticket.dst_chain,
      amount: formatUnits(BigInt(ticket.amount), token.decimals),
      receiver: isEvmChain(ticket.src_chain)
        ? ticket.receiver.toLowerCase()
        : ticket.receiver,
    });

    const finializedTicket = { ticket: { ...ticket, finalized: true } };
    if ("Ok" in result) {
      posthog.capture("ticket generate ok", {
        ...ticket,
        token_id: ticket.token,
      });
      return finializedTicket;
    }

    const error = formatGenerateTicketError(result.Err);
    if (
      error?.includes("AlreadyProcessed") ||
      error?.includes("AlreadySubmitted") ||
      error?.includes("already exists")
    ) {
      return finializedTicket;
    }
    posthog.capture("ticket generate error", {
      ...ticket,
      token_id: ticket.token,
      error,
    });
    return { message: error, ticket: { ...ticket, finalized: false } };
  }

  onMint(params: OnBridgeParams): Promise<string> {
    throw new Error("Method not implemented.");
  }

  async getTicketStatus(ticket_id: string): Promise<TicketStatusResult> {
    const res = await this.actor.release_token_status(ticket_id);
    let status = Object.keys(res)[0] as TicketStatus;
    const statusValue = Object.values(res)[0];
    let tx_hash = "";
    if (
      [
        TicketStatus.Confirmed,
        TicketStatus.Submitted,
        TicketStatus.Sending,
      ].includes(status)
    ) {
      status =
        TicketStatus.Confirmed === status ? TicketStatus.Finalized : status;
      tx_hash = statusValue ?? "";
    }
    return {
      status,
      tx_hash,
    };
  }

  async getOutputTicketStatus(ticket_id: string): Promise<TicketStatus> {
    // const res = await this.actor.generate_ticket_status(ticket_id);
    // const status = Object.keys(res)[0];
    // return status as TicketStatus;
    throw new Error("Method not implemented.");
  }

  static validateAddress(addr: string): boolean {
    return validate(addr, Network.mainnet);
  }

  async getBridgeFee(
    targetChainId: ChainID,
    token?: Token,
  ): Promise<BridgeFee> {
    const [[platform_fee], []] =
      await this.actor.get_platform_fee(targetChainId);

    return Promise.resolve({
      fee: platform_fee ?? BigInt(0),
      symbol: "BTC",
      decimals: 8,
    });
  }
}

export function transformToken(hubToken: TokenResp) {
  const { decimals, icon, symbol, token_id } = hubToken;
  const token: Token = {
    id: symbol.toLowerCase(),
    decimals,
    icon: icon[0],
    symbol,
    token_id,
    balance: 0n,
    name: symbol,
    fee: 0n,
    chain_id: ChainID.BitcoinBrc20,
  };
  return token;
}

export async function getBrc20TokenBalance(
  ticker: string,
  decimals: number,
  address?: string,
) {
  let balance = 0n;
  let available = 0n;
  try {
    if (!ticker) {
      throw new Error("Ticker is required");
    }
    if (!address) {
      throw new Error("Address is required");
    }
    const balanceResult = await fetch(
      `https://open-api.unisat.io/v1/indexer/address/${address}/brc20/${ticker}/info`,
      {
        headers: {
          Authorization:
            "Bearer 82d20a04f30da508a8381edb8ccf5b374ed937049da56df573a312e44aceae27",
          accept: "application/json",
        },
      },
    ).then((res) => res.json());
    if (balanceResult.code === 0) {
      balance = parseAmount(balanceResult.data.overallBalance, decimals);
      available = parseAmount(balanceResult.data.overallBalance, decimals);
    }
  } catch (error) {}
  return {
    balance,
    composed_balance: {
      available,
    },
  };
}
