import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import "./index.css";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import Layout from "./layout.tsx";
import Runes from "./routes/runes.tsx";
import ICP from "./routes/icp.tsx";
import Ckbtc from "./routes/ckbtc.tsx";
import Brc20 from "./routes/brc20.tsx";
import Doge from "./routes/doge.tsx";

// trigger deploy
const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Navigate to="/runes" replace />,
      },
      {
        path: "runes/",
        element: <Runes />,
        children: [
          {
            path: ":tab",
            element: <Runes />,
          },
        ],
      },
      {
        path: "brc20/",
        element: <Brc20 />,
        children: [
          {
            path: ":tab",
            element: <Brc20 />,
          },
        ],
      },
      {
        path: "icp/",
        element: <ICP />,
        children: [
          {
            path: ":tab",
            element: <ICP />,
          },
        ],
      },
      {
        path: "ckbtc",
        element: <Ckbtc />,
      },
      {
        path: "doge",
        element: <Doge />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ChakraProvider>
      <RouterProvider router={router} />
    </ChakraProvider>
  </React.StrictMode>,
);
