import { Text, VStack } from "@chakra-ui/react";
import { CkBTCWidget, ChainName, ChainID, AssetType } from "@omnity/widget";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export default function Ckbtc() {
  const [searchParams] = useSearchParams();
  const sourceChain = searchParams.get("sourceChain") || ChainName.Bitcoin;
  const targetChain = searchParams.get("targetChain") || ChainName.Sui;
  const tokenId = searchParams.get("tokenId");

  useEffect(() => {
    document.title = `ckBTC | Omnity Network`;
  }, []);

  return (
    <VStack w={{ base: "100%" }}>
      <Text fontSize={{ base: 18, md: 24 }} mb={4} fontWeight={300}>
        Omnity Hub - BTC
      </Text>
      {/* <WidgetCkbtc /> */}
      <CkBTCWidget
        assetType={AssetType.ckbtc}
        customs={ChainID.BitcoinckBTC}
        sourceChain={sourceChain as ChainName}
        targetChain={targetChain as ChainName}
        tokenIds={tokenId ? [tokenId] : []}
        network="mainnet"
        reversible
        isPeerToPeer={false}
      />
    </VStack>
  );
}
