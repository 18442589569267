import RevertButton from "../components/common/RevertButton";
import CurrentWallet from "../components/common/CurrentWallet";
import {
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack,
  Switch,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { ChainName } from "../types";
import ChainButton from "@components/common/ChainButton";
import TokenBalance from "@components/TokenBalance";
import { ChainSubmitButton } from "@components/SubmitButton/BaseSubmit";
import { BTC_DECIMALS, MIN_DOGE_TRANSFER_AMOUNT } from "src/utils/constants";
import { SubmitTransfer } from "@components/SubmitButton/SubmitButton";
import TokenSelector from "@components/TokenSelector";
import { useTransferContext } from "@context/TransferContext";
import DepositDogeAddressModal from "@components/DepositDogeAddressModal";

export default function TransferDoge({ width }: { width?: number }) {
  const {
    sourceChain,
    targetChain,
    targetAddr,
    useConnectWalletForReceiver,
    onToggleConnectWalletForReceiver,
    onTargetAddrChange,
    amount,
    onAmountChange,
    reversible,
    onRevert,
    token,
    onTokenChange,
  } = useTransferContext();

  const inputBoxWrapperBg = useColorModeValue("white", "gray.800");
  const inputTextColor = useColorModeValue("gray.800", "gray.200");
  const textColor = useColorModeValue("#333", "#eee");
  const boxWrapperBg = useColorModeValue("#eee", "gray.700");

  const isTransfer = sourceChain === ChainName.Doge;

  return (
    <VStack w={{ base: "100%", md: width }} gap={6}>
      <VStack
        width="99%"
        p={{ base: 4, md: 6 }}
        borderRadius={8}
        gap={4}
        bg={inputBoxWrapperBg}
        color={textColor}
      >
        <Stack
          flexDir={{ base: "column", md: "row" }}
          alignItems="center"
          gap={4}
          w="100%"
        >
          <VStack w={{ base: "100%" }} flex={1} alignItems="flex-start" gap={1}>
            <HStack w="100%" justifyContent="space-between">
              <Text fontWeight={600}>From</Text>
              <CurrentWallet chain={sourceChain} isSource />
            </HStack>
            <ChainButton chainName={sourceChain} isSource />
          </VStack>

          <RevertButton reversible={reversible} onRevert={onRevert} />

          <VStack w={{ base: "100%" }} flex={1} alignItems="flex-start" gap={1}>
            <HStack w="100%" justifyContent="space-between">
              <Text fontWeight={600}>To</Text>
              {useConnectWalletForReceiver && targetAddr && (
                <CurrentWallet chain={targetChain} isSource={false} />
              )}
            </HStack>
            <ChainButton chainName={targetChain} />
          </VStack>
        </Stack>

        {!isTransfer && (
          <VStack w="100%" alignItems="flex-start" gap={1}>
            <VStack w="100%" alignItems="flex-start" gap={1}>
              <Text fontWeight={600}>Amount</Text>
              <VStack w="100%" bg={boxWrapperBg} borderRadius={4} p={4}>
                <HStack w="100%" py={2}>
                  <Input
                    placeholder="0"
                    color={inputTextColor}
                    flex={1}
                    value={amount}
                    fontSize={{
                      base: 36 * (amount.length > 6 ? 0.8 : 1),
                      md: 60 * (amount.length > 6 ? 0.8 : 1),
                    }}
                    py={`${amount.length > 6 ? 6 : 0}px`}
                    lineHeight={1}
                    px={0}
                    fontWeight={600}
                    borderWidth={0}
                    height="100%"
                    outline="none"
                    _focus={{
                      borderWidth: 0,
                      outline: "none",
                      boxShadow: "none",
                    }}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      // fraction not longer than decimals
                      let [_, fraction] = newValue.split(".");
                      if (fraction && fraction.length > BTC_DECIMALS) {
                        return;
                      }
                      if (
                        /^\d+(\.)?(\d+)?$/.test(newValue) ||
                        newValue === ""
                      ) {
                        onAmountChange(e.target.value);
                      }
                    }}
                  />
                  <TokenSelector token={token} setToken={onTokenChange} />
                </HStack>
                <HStack
                  w="100%"
                  alignItems="center"
                  justifyContent="flex-end"
                  color="#999"
                >
                  <HStack fontSize={{ base: 14, md: 16 }}>
                    <Text>Balance:</Text>
                    <TokenBalance />
                  </HStack>
                </HStack>
              </VStack>
            </VStack>
            <HStack w="100%" justifyContent="flex-end" pr={2}>
              <Text fontSize={12} color="gray.500">
                Minimum {isTransfer ? "transfer" : "redeem"} amount{" "}
                {MIN_DOGE_TRANSFER_AMOUNT}
              </Text>
            </HStack>
          </VStack>
        )}

        <VStack w="100%" alignItems="flex-start" gap={1}>
          <HStack w="100%" justifyContent="space-between">
            <Text fontWeight={600}>Receiver</Text>
            <HStack cursor="pointer">
              {isTransfer && (
                <FormControl display="flex" alignItems="center">
                  <FormLabel
                    htmlFor="connected-target-addr"
                    mb="0"
                    cursor="pointer"
                  >
                    use connected wallet
                  </FormLabel>
                  <Switch
                    id="connected-target-addr"
                    isChecked={useConnectWalletForReceiver}
                    onChange={onToggleConnectWalletForReceiver}
                  />
                </FormControl>
              )}
            </HStack>
          </HStack>
          <HStack w="100%" borderRadius={4}>
            {((useConnectWalletForReceiver && targetAddr) ||
              !useConnectWalletForReceiver ||
              !isTransfer) && (
              <Input
                color={inputTextColor}
                bg={boxWrapperBg}
                py={4}
                px={2}
                lineHeight={1}
                borderWidth={0}
                fontFamily="monospace"
                height="100%"
                outline="none"
                placeholder={`${targetChain} Address`}
                value={targetAddr}
                onChange={(e) =>
                  (!useConnectWalletForReceiver || !isTransfer) &&
                  onTargetAddrChange(e.target.value)
                }
                _focus={{
                  borderWidth: 0,
                  outline: "none",
                  boxShadow: "none",
                }}
              />
            )}
            {useConnectWalletForReceiver && !targetAddr && targetChain && (
              <ChainSubmitButton chainName={targetChain} forSubmit={false} />
            )}
          </HStack>
        </VStack>
      </VStack>

      <VStack w="100%" gap={2}>
        <SubmitTransfer />
        {isTransfer && <DepositDogeAddressModal />}
      </VStack>
    </VStack>
  );
}
