import { ReactNode, createContext, useEffect, useMemo, useState } from "react";
import { atomWithStorage } from "jotai/utils";
import { useAtom } from "jotai";
import PubSub from "pubsub-js";
import { EventType, WalletType } from "./types";
import ICPWalletKitModal from "./ICPWalletKitModal";
import { HttpAgent } from "@dfinity/agent";
import { IDL } from "@dfinity/candid";
import { idlFactory as LedgerInterfaceFactory } from "./ledger.did";
import { ICPWalletKitProviderProps } from "./types";

const LEDGER_CANISTER_ID = "ryjl3-tyaaa-aaaaa-aaaba-cai";

interface Token {
  amount: number;
  currency: string;
  image: string;
  name: string;
  value: number;
}

declare global {
  interface Window {
    ic: {
      bitfinityWallet: any;
      plug?: {
        requestConnect: ({
          whitelist,
          host,
        }: {
          whitelist?: string[];
          host?: string;
        }) => Promise<string>;
        principalId?: string;
        requestBalance: () => Promise<Token[]>;
        agent: HttpAgent;
        isConnected: () => Promise<boolean>;
        requestTransfer: (params: {
          to: String;
          amount: number;
          opts?: {
            fee?: number;
            memo?: string;
            from_subaccount?: Number;
            created_at_time?: {
              timestamp_nanos: number;
            };
          };
        }) => Promise<{ height: number }>;
        createActor: <T>(params: any) => any;
        sessionManager: {
          sessionData: {
            principalId: string;
            agent: HttpAgent;
          };
        };
      };
    };
  }
}
function generateRandomBigInt32() {
  return Math.floor(Math.random() * Math.pow(2, 32));
}

export const ICPWalletKitProviderContext =
  createContext<ICPWalletKitProviderProps | null>(null);

const accountAtom = atomWithStorage<{
  address: string;
  connector?: WalletType;
}>("iwk.account", {
  address: "",
  connector: undefined,
});

export function ICPWalletProvider({ children }: { children: ReactNode }) {
  const [account, setAccount] = useAtom(accountAtom);
  const [showModal, setShowModal] = useState(false);
  const [whitelist, setWhitelist] = useState<string[]>([]);

  useEffect(() => {
    const connectListener = PubSub?.subscribe(
      EventType.ON_CONNECT,
      (
        _: string,
        { address, connector }: { address: string; connector: WalletType },
      ) => {
        setAccount({ address, connector });
      },
    );
    return () => {
      PubSub?.unsubscribe(connectListener);
    };
  }, [setAccount]);

  const contextValue = useMemo(
    () => ({
      address: account.address,
      connector: account.connector,
      onShowModal: ({ whitelist }: { whitelist: string[] }) => {
        setShowModal(true);
        setWhitelist(whitelist);
      },
      onHideModal: () => {
        setShowModal(false);
      },
      onDisconnect: () => {
        setAccount({ address: "", connector: undefined });
      },
      createActor: async (
        canisterId: string,
        interfaceFactory: IDL.InterfaceFactory,
        sender: string,
      ) => {
        if (account.connector === WalletType.Plug) {
          await window.ic.plug?.requestConnect({
            whitelist: [canisterId],
          });
          if (window.ic.plug?.principalId !== sender) {
            throw new Error(
              `Principal ID mismatch, please switch to ${sender}`,
            );
          }
          const agent = window.ic?.plug?.agent;
          return window.ic?.plug?.createActor({
            interfaceFactory,
            canisterId: canisterId,
            agent,
          });
        } else if (account.connector === WalletType.Bitfinity) {
          const bitfinityWallet = window.ic.bitfinityWallet;
          await bitfinityWallet?.requestConnect({
            whitelist: [canisterId],
          });
          const principal = await bitfinityWallet.getPrincipal();
          if (principal.toText() !== sender) {
            throw new Error(
              `Principal ID mismatch, please switch to ${sender}`,
            );
          }
          // const agent = bitfinityWallet?.agent;
          return bitfinityWallet?.createActor({
            interfaceFactory,
            canisterId: canisterId,
          });
        }
        throw new Error("No wallet connected");
      },
      transfer: async (params: { to: string; amount: bigint }) => {
        if (account.connector === WalletType.Plug) {
          const result = await window.ic?.plug?.requestTransfer({
            to: params.to,
            amount: Number(params.amount.toString()),
            opts: {
              memo: "",
            },
          });
          return result?.height;
        } else if (account.connector === WalletType.Bitfinity) {
          let blockHeight;
          const TRANSFER_ICP_TX = {
            idl: LedgerInterfaceFactory,
            canisterId: LEDGER_CANISTER_ID,
            methodName: "send_dfx",
            args: [
              {
                to: params.to,
                fee: { e8s: BigInt(10000) },
                amount: { e8s: BigInt(params.amount) },
                memo: generateRandomBigInt32(),
                from_subaccount: [],
                created_at_time: [],
              },
            ],
            onSuccess: async (res: any) => {
              blockHeight = res;
            },
            onFail: (res: any) => {},
          };
          await window.ic.bitfinityWallet.batchTransactions([TRANSFER_ICP_TX]);
          return blockHeight;
        }
        throw new Error("No wallet connected");
      },
    }),
    [account.address, account.connector, setAccount],
  );

  return (
    <ICPWalletKitProviderContext.Provider value={contextValue}>
      {children}
      <ICPWalletKitModal
        open={showModal}
        onClose={contextValue.onHideModal}
        whitelist={[
          "nlgkm-4qaaa-aaaar-qah2q-cai",
          "7ywcn-nyaaa-aaaar-qaeza-cai",
        ]}
      />
    </ICPWalletKitProviderContext.Provider>
  );
}
