import {
  idlFactory as SuiRouteInterfaceFactory,
  _SERVICE,
} from "./candids/SuiRoute.did";
import {
  Token,
  BridgeStep,
  OnBridgeParams,
  OnBurnParams,
  Ticket,
  GenerateTicketResult,
  TicketStatusResult,
  ChainID,
  BridgeFee,
  Chain,
  AssetType,
  TicketAction,
  TxStatus,
} from "@types";
import BaseService from "./BaseService";
import { ActorSubclass } from "@dfinity/agent";
import { createActor } from "./candids";
import { DEFAULT_TOKEN } from "src/utils/constants";
import { getFullnodeUrl, SuiClient } from "@mysten/sui/client";
import { Transaction } from "@mysten/sui/transactions";
import { formatGenerateTicketError, formatTicketAction } from "@utils/helper";
import posthog from "posthog-js";

export default class SuiRouteService extends BaseService {
  actor: ActorSubclass<_SERVICE>;
  constructor(chain: Chain) {
    super(chain);
    this.actor = createActor<_SERVICE>(
      chain.canister_id,
      SuiRouteInterfaceFactory,
    );
  }
  async getTokenList(): Promise<Token[]> {
    try {
      const tokenList = await this.actor.get_token_list();

      const tokens = await Promise.all(
        tokenList.map(async (t) => {
          try {
            const [suiToken] = await this.actor.sui_token(t.token_id);
            if (!suiToken) {
              return null;
            }

            return {
              id: suiToken.type_tag,
              name: t.token_id.split("-")[2]!,
              symbol: t.symbol,
              icon: t.icon[0],
              decimals: t.decimals,
              token_id: t.token_id,
              chain_id: this.chain.chain_id,
              balance: 0n,
              fee: 0n,
              extra: suiToken,
            };
          } catch (error) {
            return null;
          }
        }),
      );
      if (this.chain.asset_type === AssetType.ckbtc) {
        return tokens
          .filter((t) => t !== null)
          .filter((t) => t?.token_id === DEFAULT_TOKEN[ChainID.BitcoinckBTC]);
      }
      return tokens.filter((t) => t !== null) as Token[];
    } catch (error) {
      return [];
    }
  }

  async fetchTokens(token_ids?: string[], address?: string): Promise<Token[]> {
    let tokenList = this.chain.token_list || [];

    if (Array.isArray(token_ids) && token_ids.length > 0) {
      tokenList = token_ids
        .map((id) => tokenList.find((r) => r.token_id === id))
        .filter((t) => !!t) as any;
    }
    if (!address) {
      return tokenList;
    }
    try {
      const rpcUrl = getFullnodeUrl("mainnet");
      const client = new SuiClient({ url: rpcUrl });

      return await Promise.all(
        tokenList.map(async (t) => {
          try {
            const result = await client.getBalance({
              owner: address,
              coinType: t.id,
            });

            return {
              ...t,
              balance: BigInt(result.totalBalance),
            };
          } catch (error) {
            return t;
          }
        }),
      );
    } catch (error) {
      return tokenList;
    }
  }

  getBridgeSteps(token?: Token): BridgeStep[] {
    return [
      {
        title: "Bridge",
        description: "Pay Fee & Burn Token",
      },
    ];
  }
  async onBridge(params: OnBridgeParams): Promise<string> {
    const { token, targetChainId, sourceAddr, amount, targetAddr, transfer } =
      params;
    if (!transfer) {
      throw new Error("Transfer not defined");
    }
    const tx = new Transaction();
    const actionPackage = await this.actor.sui_port_action();
    const [fee] = await this.actor.get_redeem_fee(targetChainId);
    if (fee === undefined) {
      throw new Error("Redeem fee not defined");
    }

    const feeAccount = await this.actor.get_fee_account();
    const feeCoin = tx.splitCoins(tx.gas, [fee]);
    tx.moveCall({
      target: `${actionPackage.package}::action::collect_fee`,
      arguments: [feeCoin, tx.object(feeAccount)],
      typeArguments: ["0x2::sui::SUI"],
    });

    const rpcUrl = getFullnodeUrl("mainnet");
    const client = new SuiClient({ url: rpcUrl });
    const balances = await client.getCoins({
      owner: sourceAddr,
      coinType: token.id,
    });
    const coin = balances.data.find((c) => BigInt(c.balance) >= amount);
    if (!coin) {
      throw new Error("Insufficient balance");
    }

    const suiRouteAddrResult = await this.actor.sui_route_address({
      ChainKey: null,
    });
    if ("Err" in suiRouteAddrResult) {
      throw new Error(
        `Failed to get SuiRoute address: ${suiRouteAddrResult.Err}`,
      );
    }
    const burnCoin = tx.splitCoins(coin.coinObjectId, [amount]);

    tx.moveCall({
      target: `${actionPackage.package}::action::burn_coin`,
      arguments: [burnCoin, tx.object(suiRouteAddrResult.Ok)],
      typeArguments: [token.id],
    });

    tx.moveCall({
      target: `${actionPackage.package}::action::redeem`,
      arguments: [
        tx.pure.string(targetChainId),
        tx.pure.string(token.token_id),
        tx.pure.string(targetAddr),
        tx.pure.u64(amount),
        tx.pure.string(""),
      ],
    });

    const digest = await transfer(tx);
    return digest;
  }
  onBurn(params: OnBurnParams): Promise<string> {
    throw new Error("Method not implemented.");
  }
  onMint(params: OnBridgeParams): Promise<string> {
    throw new Error("Method not implemented.");
  }
  async generateTicket(ticket: Ticket): Promise<GenerateTicketResult> {
    const result = await this.actor.generate_ticket({
      digest: ticket.ticket_id!,
      action: formatTicketAction(ticket.type),
      memo: [],
      target_chain_id: ticket.dst_chain,
      token_id: ticket.token,
      amount: BigInt(ticket.amount),
      sender: ticket.sender,
      receiver: ticket.receiver,
    });

    const finializedTicket = { ticket: { ...ticket, finalized: true } };
    if ("Ok" in result) {
      posthog.capture("ticket generate ok", {
        ...ticket,
        token_id: ticket.token,
      });
      return finializedTicket;
    }
    const error = formatGenerateTicketError(result.Err);
    if (error?.includes("already exists")) {
      return finializedTicket;
    }
    posthog.capture("ticket generate error", {
      ...ticket,
      token_id: ticket.token,
      error,
    });
    return { message: error, ticket: { ...ticket, finalized: false } };
  }
  getTicketStatus(ticket_id: string): Promise<TicketStatusResult> {
    throw new Error("Method not implemented.");
  }
  static async getTxStatus(ticket: Ticket): Promise<TxStatus> {
    return Promise.resolve("success");
  }
  async getBridgeFee(
    targetChainId: ChainID,
    token?: Token,
  ): Promise<BridgeFee> {
    const [fee] = await this.actor.get_redeem_fee(targetChainId);
    return {
      fee: fee ?? 0n,
      symbol: "SUI",
      decimals: 9,
    };
  }
}
