import { Chain as EvmChain } from "viem";
import {
  AssetType,
  Chain,
  ChainID,
  ChainName,
  ChainState,
  ChainType,
  IndexerTicketStatus,
  ServiceType,
  TicketAction,
} from "@types";
import {
  bevm,
  bitlayer,
  bsquared,
  bitfinity,
  ailayer,
} from "@wallet-kits/evm-wallet-kit/networks";
import {
  AILayer,
  Bevm,
  BitLayer,
  Bitcoin,
  Bitfinity,
  Bob,
  Bsquared,
  Core,
  Doge,
  Ethereum,
  ICP,
  Merlin,
  Osmosis,
  Rootstock,
  Solana,
  Sui,
  Ton,
  XLayer,
} from "../components/icons";
import React from "react";
import { rootstock, bob, merlin, xLayer, mainnet, coreDao } from "viem/chains";

export const TOAST_ERROR_DURATION = 8000;

export const FETCH_TOKEN_INTERVAL = 30000;

export const CHECK_TICKET_STATUS_INTERVAL = 10000;

export const CHECK_TX_STATUS_INTERVAL = 3000;

export const FETCH_BTC_FEE_RATE_INTERVAL = 60000;

export const ICP_DECIMALS = 8;
export const BTC_DECIMALS = 8;

export const BTC_ICON = "https://bridge.omnity.network/bitcoin.svg";

export const DEFAULT_TOKEN = {
  [ChainID.Bitcoin]: "Bitcoin-runes-HOPE•YOU•GET•RICH",
  [ChainID.sICP]: "sICP-native-ICP",
  [ChainID.BitcoinBrc20]: "Bitcoinbrc20-brc20-ORDI",
  [ChainID.BitcoinckBTC]: "sICP-icrc-ckBTC",
  [ChainID.Doge]: "dogecoin-native-DOGE",
};

export const ADD_RUNES_SUFFIX = ".OT";

export const BADGE_COLORS = {
  [TicketAction.Mint]: "green",
  [TicketAction.Burn]: "red",
  [TicketAction.Transfer]: "blue",
  [TicketAction.Redeem]: "teal",
};

export const TICKET_STATUS_COLORS = {
  [IndexerTicketStatus.Generating]: "red.400",
  [IndexerTicketStatus.Unknown]: "pink.400",
  [IndexerTicketStatus.Pending]: "pink.400",
  [IndexerTicketStatus.WaitingForConfirmBySrc]: "orange.400",
  [IndexerTicketStatus.WaitingForConfirmByDest]: "teal.400",
  [IndexerTicketStatus.Finalized]: "green.400",
};

export const CHAIN_LIST: {
  [key in ChainID]: {
    chainName: ChainName;
    evmChain?: EvmChain;
    serviceType: ServiceType;
    icon: React.ElementType;
    assets: AssetType[];
  };
} = {
  [ChainID.Bitcoin]: {
    chainName: ChainName.Bitcoin,
    serviceType: ServiceType.Customs,
    icon: Bitcoin,
    assets: [AssetType.runes],
  },
  [ChainID.BitcoinBrc20]: {
    chainName: ChainName.Bitcoin,
    serviceType: ServiceType.Customs,
    icon: Bitcoin,
    assets: [AssetType.brc20],
  },
  [ChainID.eICP]: {
    chainName: ChainName.ICP,
    serviceType: ServiceType.Route,
    icon: ICP,
    assets: [AssetType.runes, AssetType.brc20],
  },
  [ChainID.Ethereum]: {
    chainName: ChainName.Ethereum,
    serviceType: ServiceType.Route,
    evmChain: {
      ...mainnet,
      rpcUrls: {
        default: {
          http: [
            "https://rpc.ankr.com/eth",
            "https://eth.drpc.org",
            "https://cloudflare-eth.com",
          ],
        },
      },
    },
    icon: Ethereum,
    assets: [AssetType.runes, AssetType.icrc],
  },
  [ChainID.BitLayer]: {
    chainName: ChainName.BitLayer,
    serviceType: ServiceType.Route,
    evmChain: bitlayer,
    icon: BitLayer,
    assets: [AssetType.runes, AssetType.brc20],
  },
  [ChainID.Bitfinity]: {
    chainName: ChainName.Bitfinity,
    serviceType: ServiceType.Route,
    evmChain: bitfinity,
    icon: Bitfinity,
    assets: [AssetType.runes, AssetType.brc20, AssetType.icrc],
  },
  [ChainID.BEVM]: {
    chainName: ChainName.BEVM,
    serviceType: ServiceType.Route,
    evmChain: bevm,
    icon: Bevm,
    assets: [AssetType.runes],
  },
  [ChainID.BSquared]: {
    chainName: ChainName.BSquared,
    serviceType: ServiceType.Route,
    evmChain: bsquared,
    icon: Bsquared,
    assets: [AssetType.runes],
  },
  [ChainID.XLayer]: {
    chainName: ChainName.XLayer,
    serviceType: ServiceType.Route,
    evmChain: xLayer,
    icon: XLayer,
    assets: [AssetType.runes],
  },
  [ChainID.Merlin]: {
    chainName: ChainName.Merlin,
    serviceType: ServiceType.Route,
    evmChain: merlin,
    icon: Merlin,
    assets: [AssetType.runes],
  },
  [ChainID.Bob]: {
    chainName: ChainName.Bob,
    serviceType: ServiceType.Route,
    evmChain: bob,
    icon: Bob,
    assets: [AssetType.runes],
  },
  [ChainID.RootStock]: {
    chainName: ChainName.Rootstock,
    serviceType: ServiceType.Route,
    evmChain: rootstock,
    icon: Rootstock,
    assets: [AssetType.runes],
  },
  [ChainID.AILayer]: {
    chainName: ChainName.AILayer,
    serviceType: ServiceType.Route,
    evmChain: ailayer,
    icon: AILayer,
    assets: [AssetType.runes],
  },
  [ChainID.sICP]: {
    chainName: ChainName.ICP,
    serviceType: ServiceType.Customs,
    icon: ICP,
    assets: [AssetType.icrc],
  },
  [ChainID.Solana]: {
    chainName: ChainName.Solana,
    serviceType: ServiceType.Route,
    icon: Solana,
    assets: [AssetType.runes, AssetType.icrc],
  },
  [ChainID.Core]: {
    chainName: ChainName.Core,
    serviceType: ServiceType.Route,
    evmChain: coreDao,
    icon: Core,
    assets: [AssetType.runes, AssetType.ckbtc],
  },
  [ChainID.Osmosis]: {
    chainName: ChainName.Osmosis,
    serviceType: ServiceType.Route,
    icon: Osmosis,
    assets: [AssetType.ckbtc, AssetType.icrc, AssetType.doge],
  },
  [ChainID.BitcoinckBTC]: {
    chainName: ChainName.Bitcoin,
    serviceType: ServiceType.Customs,
    icon: Bitcoin,
    assets: [AssetType.ckbtc],
  },
  [ChainID.Ton]: {
    chainName: ChainName.Ton,
    serviceType: ServiceType.Route,
    icon: Ton,
    assets: [AssetType.runes, AssetType.ckbtc, AssetType.icrc],
  },
  [ChainID.Doge]: {
    chainName: ChainName.Doge,
    serviceType: ServiceType.Customs,
    icon: Doge,
    assets: [AssetType.doge],
  },
  [ChainID.Sui]: {
    chainName: ChainName.Sui,
    serviceType: ServiceType.Route,
    icon: Sui,
    assets: [AssetType.ckbtc, AssetType.icrc],
  },
};

export const CKBTC_CHAIN_LIST: Chain[] = [
  {
    chain_id: ChainID.BitcoinckBTC,
    chain_name: ChainName.Bitcoin,
    canister_id: "",
    fee_token: [],
    chain_state: ChainState.Active,
    chain_type: ChainType.Settlement,
    counterparties: [ChainID.Osmosis, ChainID.Core, ChainID.Ton],
    service_type: ServiceType.Customs,
    contract_address: "",
    asset_type: AssetType.ckbtc,
  },
  {
    chain_id: ChainID.Osmosis,
    chain_name: ChainName.Osmosis,
    canister_id: "ystyg-kaaaa-aaaar-qaieq-cai",
    fee_token: [],
    chain_state: ChainState.Active,
    chain_type: ChainType.ExecutionChain,
    counterparties: [ChainID.BitcoinckBTC],
    service_type: ServiceType.Route,
    contract_address:
      "osmo10c4y9csfs8q7mtvfg4p9gd8d0acx0hpc2mte9xqzthd7rd3348tsfhaesm",
    asset_type: AssetType.ckbtc,
  },
  {
    chain_id: ChainID.Core,
    chain_name: ChainName.Core,
    canister_id: "vopxt-5qaaa-aaaar-qajnq-cai",
    fee_token: [],
    chain_state: ChainState.Active,
    chain_type: ChainType.ExecutionChain,
    counterparties: [ChainID.BitcoinckBTC],
    service_type: ServiceType.Route,
    asset_type: AssetType.ckbtc,
    evm_chain: coreDao,
    contract_address: "0x1Ad8cec9E5a4A441FE407785E188AbDeb4371468",
  },
  {
    chain_id: ChainID.Ton,
    chain_name: ChainName.Ton,
    canister_id: "p5ykc-qaaaa-aaaar-qalyq-cai",
    fee_token: [],
    chain_state: ChainState.Active,
    chain_type: ChainType.ExecutionChain,
    counterparties: [ChainID.BitcoinckBTC],
    service_type: ServiceType.Route,
    asset_type: AssetType.ckbtc,
  },
  {
    chain_id: ChainID.Sui,
    chain_name: ChainName.Sui,
    canister_id: "net34-wiaaa-aaaar-qamkq-cai",
    fee_token: [],
    chain_state: ChainState.Active,
    chain_type: ChainType.ExecutionChain,
    counterparties: [ChainID.BitcoinckBTC],
    service_type: ServiceType.Route,
    asset_type: AssetType.ckbtc,
    contract_address:
      "0xf0e39bd32ced9b1990de2ffbd6c4d3643273d50a6bb9f4bbb5d70d72aac31826",
  },
];

export const DOGECOIN_CHAIN_LIST: Chain[] = [
  {
    chain_id: ChainID.Doge,
    chain_name: ChainName.Doge,
    canister_id: "nnqqa-aaaaa-aaaar-qamla-cai",
    fee_token: [],
    chain_state: ChainState.Active,
    chain_type: ChainType.Settlement,
    counterparties: [ChainID.Osmosis],
    service_type: ServiceType.Customs,
    contract_address: "",
    asset_type: AssetType.doge,
  },
  {
    chain_id: ChainID.Osmosis,
    chain_name: ChainName.Osmosis,
    canister_id: "ystyg-kaaaa-aaaar-qaieq-cai",
    fee_token: [],
    chain_state: ChainState.Active,
    chain_type: ChainType.ExecutionChain,
    counterparties: [ChainID.Doge],
    service_type: ServiceType.Route,
    contract_address:
      "osmo10c4y9csfs8q7mtvfg4p9gd8d0acx0hpc2mte9xqzthd7rd3348tsfhaesm",
    asset_type: AssetType.doge,
  },
];

export const TEMPORARY_DISABLED_CHAINS = [];

export const NON_MINTABLE_L2_CHAINS = [
  ChainName.Bitcoin,
  ChainName.Ethereum,
  ChainName.Ton,
  ChainName.Solana,
  ChainName.Osmosis,
];

export const NON_BURNABLE_L2_CHAINS = [
  ChainName.Bitcoin,
  ChainName.Ethereum,
  ChainName.Ton,
  ChainName.Solana,
  ChainName.Osmosis,
];

export const MIN_CKBTC_TRANSFER_AMOUNT = "0.0006";
export const MIN_CKBTC_REDEEM_AMOUNT = "0.0005";

export const MIN_DOGE_TRANSFER_AMOUNT = "0.1";
